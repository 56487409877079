       <template>
  <div>
  <div class="header-wrap">
    <v-toolbar
      style = 'background: #7295BE;
                box-shadow: 0px 1px 12px rgba(51, 51, 51, 0.15);
                border-radius: 23px;
                position:relative;
                width:100%'
      height = '45'>
      <v-img class="icon-rounded" :src="require('../assets/logo.png')"  max-height="34" max-width="34"/><span class="logo-text">Keeper</span>
       <v-spacer></v-spacer>
<v-text-field placeholder="Поиск"
@focus="searchClosed = false"
@blur="searchClosed = true"
filled
dense
class="expanding-search mt-1"
:class="{'closed':searchClosed && !search}"
append-icon="mdi-magnify"></v-text-field>
       <v-btn class="settings-icon">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-toolbar>
 </div>
  <v-container>
    <v-row dense class="card-menu">
        <v-col
          v-for="card in cards"
          :key="card.title"
          :cols="card.flex"
        >
          <v-card>
            <v-img
              :src="require('../assets/'+card.src)"
              class="white--text align-end"
              height="75px"
            >
              <v-card-title v-text="card.title"></v-card-title>
            </v-img>

           
          </v-card>
        </v-col>
      </v-row>
  <yandex-map
  :settings="settings"
    :coords="coords"
    :controls="['zoomControl','geolocationControl']"
    @map-was-initialized="handleMapWasInitialized"
    :zoom="10"
    :suppressMapOpenBlock= true>
    <my-component slot="balloon"></my-component>
</yandex-map>
<h5>Компании</h5>
           <v-container fluid grid-list-xl>
            <v-layout wrap justify-space-around>
              <v-list flat>
      <v-list-item-group
        v-model="model"
        color="indigo"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
        >
           <v-list-item-icon color="gray dark" width="50px"><v-img :src="'https://shop.pamapp.ru/img/sellers/'+item.id_customer+'.jpg'" height="40px"/></v-list-item-icon>
                  <v-list-item-content>
                    <router-link :to="{path:'/company/'+item.id_customer}">
                      {{item.name}}
                    </router-link>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="gray lighten-1">mdi-message</v-icon>
                    <v-icon color="gray lighten-1">mdi-phone</v-icon>
                  </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
              
            </v-layout>
            <v-stepper
    v-model="e6"
    vertical
  >
    <v-stepper-step
      :complete="e6 > 1"
      step="1"
    >
      Select an app
      <small>Summarize if needed</small>
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card
        color="grey lighten-1"
        class="mb-12"
        height="200px"
      >
       <v-slide-group
      v-model="model"
      class="pa-4"
      active-class="success"
      show-arrows
    >
      <v-slide-item
        v-for="(pp, i) in picts"
        :key="i"
        v-slot="{ active, toggle }"
      >
        <v-card
          :color="active ? undefined : 'grey lighten-1'"
          class="ma-4"
          height="80"
          width="160"
          @click="toggle"
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
          <v-img :src="'https://shop.pamapp.ru/api/images/products/'+pp.id+'/'+pp.id_default_image+'&ws_key=EN434BZH1UY6CG7TBQSKASPB3MF4GZL1'" height="70px"/>
            <v-scale-transition>
              <v-icon
                v-if="active"
                color="white"
                size="48"
                v-text="'mdi-close-circle-outline'"
              ></v-icon>
            </v-scale-transition>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group> 
        
      </v-card>
      <v-btn
        color="primary"
        @click="e6 = 2"
      >
        Continue
      </v-btn>
      <v-btn text>
        Cancel
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 2"
      step="2"
    >
      Configure analytics for this app
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card
        color="grey lighten-1"
        class="mb-12"
        height="200px"
      > <v-textarea
          outlined
          name="input-7-4"
          label="Введите данные захоронения"
          value="Фамилия Имя дата смерти рождения"
        ></v-textarea></v-card>
      <v-btn
        color="primary"
        @click="e6 = 3"
      >
        Continue
      </v-btn>
      <v-btn text>
        Cancel
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 3"
      step="3"
    >
      Select an ad format and name ad unit
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-card
        color="grey lighten-1"
        class="mb-12"
        height="200px"
      >
        
  <v-slider
        v-model="bpm"
        track-color="grey"
        always-dirty
        min="30"
        max="400"
      >
        <template v-slot:prepend>
          <v-icon
            @click="decrement"
          >
            mdi-minus
          </v-icon>
        </template>

        <template v-slot:append>
          <v-icon
            @click="increment"
          >
            mdi-plus
          </v-icon>
        </template>
      </v-slider>

      </v-card>
      <v-btn
        color="primary"
        @click="e6 = 4"
      >
        Далее
      </v-btn>
      <v-btn text>
        Отмена
      </v-btn>
    </v-stepper-content>

    <v-stepper-step step="4">
      View setup instructions
    </v-stepper-step>
    <v-stepper-content step="4">
      <v-card
        color="grey lighten-1"
        class="mb-12"
        height="200px"
      ></v-card>
      <v-btn
        color="primary"
        @click="e6 = 1"
      >
        Далее
      </v-btn>
      <v-btn text>
        Отмена
      </v-btn>
    </v-stepper-content>
  </v-stepper> 
    
          </v-container>
          <v-bottom-navigation
    :value="value"
    color="primary"
  ><v-btn>
      <v-icon x-large>mdi-account-circle</v-icon>
    </v-btn>
    <v-btn>
      <v-icon x-large>mdi-star</v-icon>
    </v-btn>
    <v-btn>
      <v-icon x-large>mdi-basket</v-icon>
    </v-btn>
     <v-btn>
      <v-icon x-large>mdi-phone</v-icon>
    </v-btn>
     <v-btn>
      <v-icon x-large>mdi-message</v-icon>
    </v-btn>
  </v-bottom-navigation>
  </v-container>
</div>



</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
    searchClosed: true,
    e6: 1,
    model: null,
    map: null,
    cards: [
        { title: 'Памятники/ритуал', src: 'bg-menu-1.png', flex: 6 },
        { title: 'Маркет', src: 'bg-menu-2.png', flex: 6 },
        { title: 'Вакансии', src: 'bg-menu-3.png', flex: 6 },
        { title: 'Лента', src: 'bg-menu-4.png', flex: 6 },
    ],
     options: [
            { label: 'label1', value: 'value1' },
            { label: 'label2', value: 'value2' }
        ],
        coords: [53.217859,
        50.221563 
         ],
      items:[],
      picts:[],
      bpm: 40,
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Documentation',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Chat',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Made with Vuetify',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
    mounted () {
      var that = this
      this.axios.get('https://shop.pamapp.ru/api/products&output_format=JSON&ws_key=EN434BZH1UY6CG7TBQSKASPB3MF4GZL1&display=full&filter[id_category_default]=17')
    .then(response => {that.picts = response.data.products;
                        /*that.items.forEach(element=>{ 

                        })*/})
    .catch(error => (console.log(error)))
    //},
  },
  methods: {
     decrement () {
        this.bpm--
      },
      increment () {
        this.bpm++
      },
     handleMapWasInitialized(map) {
      this.map=map
      var that = this
    this.axios.get('https://shop.pamapp.ru/api/sellers&output_format=JSON&ws_key=EN434BZH1UY6CG7TBQSKASPB3MF4GZL1&display=[name,id_customer,latitude,longitude]')
    .then(response => {that.items = response.data.sellers;
                        that.items.forEach(element=>{ var placemark = new ymaps.Placemark([element.longitude,element.latitude],{iconContent:element.name},{});
      that.map.geoObjects.add(placemark)})})
    .catch(error => (console.log(error)))
    },
  }
  }
</script>
<style>
.card-menu { margin-top: 55px; }  
.ymap-container {
      width: 100%;
      min-height: 250px;
      height: 25vh;
}
.icon-rounded {
  border-radius: 50%;
  margin-left: -9px;
}
.logo-text {
  margin-left: 6px;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  background: linear-gradient(103.57deg, #FDFDFE 9.72%, #7295BE 185.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
body {
font-family: Montserrat;
font-size: 42px;
font-weight: 700;
line-height: 29px;
letter-spacing: -0.025em;
text-align: left;
}
.settings-icon {
    width: 34px;
    border-radius: 50%;
    height: 34px!important;
    min-width: 34px!important;
    padding: 0 16px;
    margin-right: -9px;
}
.header-wrap { position: fixed; z-index:1;  padding: 10px;width: 100%;
}
.v-bottom-navigation { position: fixed; }
.v-card {background: #7295BE;
border: 3px solid #FFFFFF;
box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.28);
}
</style>
<style lang="sass">
  .v-list.theme--light
    background: none
    .v-list-item
      background: #7295BE
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.28)
      border-radius: 18px
      height: 36px
      margin-bottom: 10px
      .v-list-item__icon
        margin-bottom: 0
        margin-top: 4px
        margin-right: 10px
      .v-list-item__content
        a
          color: white
          font-size: 1rem
          text-decoration: none
      .v-list-item__action
          flex-direction: row
          .v-icon
            color: white
            margin-right: 5px
  .v-input.expanding-search
    color: white
    .v-icon
      color: white
    transition: max-width 0.3s
    .v-text-field__details
      display: none
    .v-input__slot
      &:before, &:after
        border-color: transparent !important
    &.closed
      max-width:45px
      .v-input__slot
        background: transparent !important
  .v-list-item__icon
    width: 60px
  .v-list-item__content
    font-size: 12px
  .v-list--flat
    width: 100%

</style>