import { render, staticRenderFns } from "./CompanyPage.vue?vue&type=template&id=29ede8a8&"
import script from "./CompanyPage.vue?vue&type=script&lang=js&"
export * from "./CompanyPage.vue?vue&type=script&lang=js&"
import style0 from "./CompanyPage.vue?vue&type=style&index=0&lang=css&"
import style1 from "./CompanyPage.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBottomNavigation,VBtn,VCard,VCardTitle,VCol,VContainer,VIcon,VImg,VRow,VSpacer,VTextField,VToolbar})
