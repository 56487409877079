<template>
  <div>
  <div class="header-wrap">
    <v-toolbar
      style = 'background: #7295BE;
                box-shadow: 0px 1px 12px rgba(51, 51, 51, 0.15);
                border-radius: 23px;
                position:relative;
                width:100%'
      height = '45'>
      <v-img class="icon-rounded" :src="require('../assets/logo.png')"  max-height="34" max-width="34"/><span class="logo-text">Keeper</span>
       <v-spacer></v-spacer>
<v-text-field placeholder="Поиск"
@focus="searchClosed = false"
@blur="searchClosed = true"
filled
dense
class="expanding-search mt-1"
:class="{'closed':searchClosed && !search}"
append-icon="mdi-magnify"></v-text-field>
       <v-btn class="settings-icon">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-toolbar>
 </div>
  <v-container>
    <v-card>
      <v-row dense class="card-menu">
        <v-img>
          <p>
           company_info.description
          </p>
          <v-action></v-action>
        {{ company_info.name }}
      </v-row>
      <v-btn>
      <v-icon x-large>mdi-account-circle</v-icon>
    </v-btn>
    <v-btn>
      <v-icon x-large>mdi-star</v-icon>
    </v-btn>
    <v-btn>
      <v-icon x-large>mdi-basket</v-icon>
    </v-btn>
     <v-btn>
      <v-icon x-large>mdi-phone</v-icon>
    </v-btn>
     <v-btn>
      <v-icon x-large>mdi-message</v-icon>
    </v-btn>
    </v-card>
  <v-row dense class="card-menu">
        <v-col
          v-for="card in cards"
          :key="card.title"
          :cols="card.flex"
        >
          <v-card>
            <v-img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="75px"
            >
              <v-card-title v-text="card.title"></v-card-title>
            </v-img>

           
          </v-card>
        </v-col>
      </v-row>
         
          </v-container>
          <v-bottom-navigation
    :value="value"
    color="primary"
  ><v-btn>
      <v-icon x-large>mdi-account-circle</v-icon>
    </v-btn>
    <v-btn>
      <v-icon x-large>mdi-star</v-icon>
    </v-btn>
    <v-btn>
      <v-icon x-large>mdi-basket</v-icon>
    </v-btn>
     <v-btn>
      <v-icon x-large>mdi-phone</v-icon>
    </v-btn>
     <v-btn>
      <v-icon x-large>mdi-message</v-icon>
    </v-btn>
  </v-bottom-navigation>
  </v-container>
</div>
</template>

<script>
import logo from '../assets/logo.svg'

export default {
  name: 'CompanyPage',
  data: () => ({
    item: null,
    cards: [
        { title: 'Заказать ритуал', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 4 },
        { title: 'Заказать памятник', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 4 },
        { title: 'Заказать благоустройство', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 4 },
        { title: 'Заказать металл и<br/>(кресты, ограда и пр.)', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
        { title: 'Заказать захоронение и<br/>благоустройство<br/>домашних животных', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
    ],
    company_info: {name:null},
    /* items: [
        { header: 'Today' },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Brunch this weekend?',
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Oui oui',
          subtitle: '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Birthday gift',
          subtitle: '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
          title: 'Recipe to try',
          subtitle: '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        },
      ], */

    logo

  }),

  mounted () {
    const that = this
    this.axios.get('https://shop.pamapp.ru/api/sellers/' + this.$route.params.id + '&output_format=JSON&ws_key=EN434BZH1UY6CG7TBQSKASPB3MF4GZL1&display=[name]').then(response => {
      that.company_info = response.data.sellers[0]
    }).catch((error) => console.log(response.toJSON()))
  } 
}
/* response %5Bname%2Cid_customer%5D */
</script>
<style>
.card-menu { margin-top: 55px; }  
.ymap-container {
      width: 100%;
      min-height: 250px;
      height: 25vh;
}
.icon-rounded {
  border-radius: 50%;
  margin-left: -9px;
}
.logo-text {
  margin-left: 6px;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  background: linear-gradient(103.57deg, #FDFDFE 9.72%, #7295BE 185.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
body {
font-family: Montserrat;
font-size: 42px;
font-weight: 700;
line-height: 29px;
letter-spacing: -0.025em;
text-align: left;
}
.settings-icon {
    width: 34px;
    border-radius: 50%;
    height: 34px!important;
    min-width: 34px!important;
    padding: 0 16px;
    margin-right: -9px;
}
.header-wrap { position: fixed; z-index:1;  padding: 10px;width: 100%;
}
.v-bottom-navigation { position: fixed; }
.v-card {background: #7295BE;
border: 3px solid #FFFFFF;
box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.28);
}
</style>
<style lang="sass">
  .v-list.theme--light
    background: none
    .v-list-item
      background: #7295BE
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.28)
      border-radius: 18px
      height: 36px
      margin-bottom: 10px
      .v-list-item__icon
        margin-bottom: 0
        margin-top: 4px
        margin-right: 10px
      .v-list-item__content
        a
          color: white
          font-size: 1rem
          text-decoration: none
      .v-list-item__action
          flex-direction: row
          .v-icon
            color: white
            margin-right: 5px
  .v-input.expanding-search
    color: white
    .v-icon
      color: white
    transition: max-width 0.3s
    .v-text-field__details
      display: none
    .v-input__slot
      &:before, &:after
        border-color: transparent !important
    &.closed
      max-width:45px
      .v-input__slot
        background: transparent !important
  .v-list-item__icon
    width: 60px
  .v-list-item__content
    font-size: 12px
  .v-list--flat
    width: 100%

</style>